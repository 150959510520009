export default {
  methods: {
    trackingUserDetails(user) {
      if (!user) { return; };

      const phoneNumber = user.phone ? user.phone_code + user.phone : '';
      const formattedPhoneNumber = phoneNumber !== '' ? phoneNumber.replace(/[+\s]/g, "") : '';

      if (typeof this.$snaptr === 'function') {
        this.$snaptr.enable({
          user_name: user.full_name ? user.full_name : user.name,
          user_email: user.email,
          user_phone_number:formattedPhoneNumber !== '' ? formattedPhoneNumber : '',
        });
      }
    },

    trackingPurchaseJourney(eventName, user, product) {
      console.log('Tracking Purchase Journey initiated');

      if (!window.dataLayer) { return; };

      console.log('Event Name:', eventName);
      console.log('User:', user);
      console.log('product:', product);

      const pushData = this.initializePushData(eventName, user, product);

      if (Array.isArray(product)) {
        this.handleProductArray(pushData, product);
      } else {
        this.handleEvent(pushData, eventName, user, product);
      }

      console.log('Data Test Layer:', window.dataLayer);
      window.dataLayer.push(pushData);
    },

    initializePushData(eventName, user, product) {
      return {
        event: eventName,
        user_id: user ? user.id : product.id,
        user_name: user ? user.full_name : product.full_name,
        user_email: user ? user.email : product.user_email,
        user_phone_number: user && user.phone ? `+${user.phone.replace(/[+\s]/g, '')}` : product && product.phone_number ? `+${product.phone_number.replace(/[+\s]/g, '')}` : '',
        country_name: user ? user.country_name : product.country_name,
        gender: user && user.gender ? (user.gender === 'ذكر' ? 'male' : 'female') : product && product.gender ? (product.gender === 'ذكر' ? 'male' : 'female') : '',
      };
    },

    handleProductArray(pushData, products) {
      pushData.items = products.map(item => ({
        item_name: item.name,
        item_id: item.id,
        num_items: 1,
        item_type: item.model_type || item.type,
        item_price: item.price,
        currency: item.currency || 'SAR'
      }));
      pushData.content_ids = products.map(item => item.id);
      pushData.value = products.reduce((acc, item) => acc + parseFloat(item.price), 0);
    },

    handleSingleProduct(pushData, product) {
      pushData.items = [{
        item_name: product.name || product.productsNames,
        item_id: product.id || product.productsIds,
        num_items: product.numberOfProducts || 1,
        item_type: product.model_type || product.type,
        item_price: product.price || product.totalPrice,
        currency: product.currency || 'SAR'
      }];
      pushData.content_ids = product.id || product.productsIds;
      pushData.value = parseFloat(product.price || product.totalPrice);
    },

    handleCompletePayment(pushData, product) {
      Object.assign(pushData, {
        user_id: product.user_id,
        user_name: product.user_name,
        user_email: product.user_email,
        phone_number: product.phone_number ? `+${product.phone_number.replace(/\D/g, '')}` : '',
        transaction_id: product.transaction_id,
        payment_method: product.payment_method,
        price: product.totalPrice,
        value: parseFloat(product.totalPrice),
        items: product.items,
        currency: product.currency || 'SAR',
        numberOfProducts: product.items.length
      });
    },

    handleUserEvent(pushData, user) {
      Object.assign(pushData, {
        user_id: user.id,
        user_name: user.full_name ? user.full_name : user.name ? user.name : '',
        user_email: user.email,
        user_phone_number: user.phone ? `+${user.phone.replace(/[+\s]/g, '')}`: '',
        country_name: user.country_name,
        gender: user.gender ? (user.gender === 'ذكر' ? 'male' : 'female') : '',
      });
    },

    handleEvent(pushData, eventName, user, product) {
      switch (eventName) {
        case 'viewItemSkipped':
          break;
        case 'CompletePayment':
          this.handleCompletePayment(pushData, product);
          break;
        case 'SignIn':
          this.handleUserEvent(pushData, user);
          break;
        case 'SignUp':
          this.handleUserEvent(pushData, user);
          break;
        default:
          this.handleSingleProduct(pushData, product);
      }
    }
  }
};
